import { styled, css } from "styled-components";
import theme from "styled-theming";

import { default as DefaultIcon } from "components/Icon/";
import { default as NavLink } from "components/Link";
import { Container as GridContainer } from "style/components/Page";
import { styleBodyM } from "style/components/Typography";
import { GUTTER } from "style/grid";
import { MQ } from "style/mediaQueries";

export const Section = styled.div.attrs<{
    $headerVisible: boolean;
}>(() => ({}))`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    ${theme("variant", {
        default: css`
            background: var(--background-default);
            border-bottom: 1px solid var(--line-on-neutral-default);
            border-top: 1px solid var(--line-on-neutral-default);
            position: sticky;
            top: 0;
            z-index: 100;
        `,
    })};
`;

export const Container = styled(GridContainer)`
    ${theme("variant", {
        default: css`
            padding: 0 0 0 ${GUTTER.SM};
            position: relative;

            &::after {
                background: var(--gradient-background-default-opacity);
                content: "";
                display: block;
                pointer-events: none;
                position: absolute;
                right: 0;
                top: 0.875rem;
                width: 4.375rem;
                height: 1.75rem;
            }

            ${MQ.FROM_M} {
                padding: 0.875rem ${GUTTER.MD};

                &::after {
                    all: unset;
                }
            }

            ${MQ.FROM_XL} {
                padding: 1.3125rem ${GUTTER.XL};
            }
        `,
        pills: css`
            padding: 0.875rem 0 0;
        `,
    })};
`;

export const Links = styled.ul`
    display: flex;
    margin: 0;
    padding: 0.875rem 3.5rem 0.875rem 0;
    row-gap: 0.4375rem;
    width: 100%;
    ${theme("variant", {
        default: css`
            column-gap: 2.1875rem;
            flex-flow: row nowrap;
            overflow-x: auto;

            ${MQ.FROM_M} {
                flex-wrap: wrap;
                overflow: visible;
            }
        `,
        pills: css`
            column-gap: 0.875rem;
            flex-flow: row wrap;
        `,
    })};

    ${MQ.FROM_M} {
        padding: 0;
        row-gap: 0.875rem;
    }
`;

export const Watermark = styled(DefaultIcon)`
    margin-right: 0.4375rem;
`;

export const LinkWrapper = styled.div`
    color: var(--text-on-neutral-primary);
    list-style: none;
    white-space: nowrap;
`;

export const Slash = styled.span`
    ${styleBodyM}
    color: var(--text-on-neutral-secondary);
`;

export const Link = styled(NavLink).attrs<{
    $selected?: boolean;
    $hasActiveChildren?: boolean;
}>(() => ({}))`
    ${styleBodyM}
    border-bottom: none;
    color: var(--text-on-neutral-primary);
    transition: color 0.2s ease-in-out;
    display: block;

    span,
    svg {
        color: currentcolor;
        transition: none;
    }

    &:hover {
        color: var(--text-on-neutral-link);
    }

    ${(props) =>
        props.$hasActiveChildren &&
        css`
            color: var(--text-on-neutral-secondary);
        `}

    ${(props) =>
        props.$selected &&
        css`
            span {
                border-bottom: 1px solid currentcolor;
            }
        `}
`;
