import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FilterProps from "./FilterProps";

const Filter = loadable(
    (): Promise<DefaultComponent<FilterProps>> =>
        import(/* webpackChunkName: "Filter" */ "./Filter"),
);
const FilterLoader = (props: FilterProps): ReactElement => (
    <Filter {...props} />
);
export default FilterLoader;
