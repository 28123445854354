import React, { ReactElement, useCallback } from "react";
import { ThemeProvider } from "styled-components";

import Filter from "components/Filter";
import useHeader from "hooks/useHeader";

import {
    Links,
    LinkWrapper,
    Link,
    Section,
    Container,
    Watermark,
    Slash,
} from "./FilterBar.styled";
import FilterBarProps from "./FilterBarProps";

const FilterBar = ({
    identifier,
    items,
    className,
    variant = "default",
    gtmClickType,
    noScroll = false,
}: FilterBarProps): ReactElement => {
    const headerState = useHeader();

    const getActiveChildren = useCallback(
        (id: number) =>
            items?.filter((item) => item.parentId === id && item.selected),
        [items],
    );

    return (
        <ThemeProvider theme={{ variant: variant }}>
            <Section className={className} $headerVisible={headerState.visible}>
                <Container
                    role="navigation"
                    aria-label="FilterBar"
                    key={identifier || `FilterBarContent`}
                >
                    <Links as="ol">
                        {items
                            ?.filter((cat) => !cat.parentId) // Only show parents on first level and handle children inside the loop
                            ?.map((item, index) => {
                                const children = item.id
                                    ? getActiveChildren(Number(item.id))
                                    : undefined;
                                const hasActiveChildren = Boolean(
                                    children && children.length > 0,
                                );
                                return (
                                    item?.url && (
                                        <LinkWrapper
                                            as="li"
                                            key={`item-${index}`}
                                        >
                                            {variant == "default" ? (
                                                <div>
                                                    <Link
                                                        to={item.url}
                                                        title={item.displayName}
                                                        aria-current={
                                                            item.selected
                                                                ? "page"
                                                                : ""
                                                        }
                                                        gtmClickType={
                                                            gtmClickType
                                                        }
                                                        noScroll={noScroll}
                                                        $selected={
                                                            item.selected
                                                        }
                                                        $hasActiveChildren={
                                                            hasActiveChildren
                                                        }
                                                    >
                                                        {item.icon && (
                                                            <Watermark
                                                                icon={`${item?.icon}28`}
                                                            />
                                                        )}
                                                        <span>
                                                            {item.displayName}
                                                        </span>
                                                    </Link>
                                                    {hasActiveChildren && (
                                                        <>
                                                            <Slash>/</Slash>
                                                            {children &&
                                                                children.map(
                                                                    (
                                                                        child,
                                                                        index,
                                                                    ) => (
                                                                        <Link
                                                                            key={`catNavChild${index}`}
                                                                            to={
                                                                                child.url
                                                                            }
                                                                            title={
                                                                                child.displayName
                                                                            }
                                                                            aria-current={
                                                                                child.selected
                                                                                    ? "page"
                                                                                    : ""
                                                                            }
                                                                            gtmClickType={
                                                                                gtmClickType
                                                                            }
                                                                            noScroll={
                                                                                noScroll
                                                                            }
                                                                            $selected={
                                                                                child.selected
                                                                            }
                                                                        >
                                                                            <span>
                                                                                {
                                                                                    child.displayName
                                                                                }
                                                                            </span>
                                                                        </Link>
                                                                    ),
                                                                )}
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <Filter
                                                    to={item.url}
                                                    title={item.displayName}
                                                    selected={item.selected}
                                                    gtmClickType={gtmClickType}
                                                    noScroll={noScroll}
                                                >
                                                    <span>
                                                        {item.displayName}
                                                    </span>
                                                </Filter>
                                            )}
                                        </LinkWrapper>
                                    )
                                );
                            })}
                    </Links>
                </Container>
            </Section>
        </ThemeProvider>
    );
};

export default React.memo(FilterBar);
